<template>
  <li class="flix-html-li" v-if="credits">
    <router-link class="flix-html-a" :to="{name: 'dashboardCredits'}">
      <flix-icon :id="'coins'" /> {{ credits.available }} {{ $t('message.credit') }}
    </router-link>
  </li>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      credits: false
    }
  },
  methods: {
    getCoins () {
      var user = this.$getUserVariables()
      this.$store.dispatch('getCredits', {
        user: user.user.md5_id,
        callback: function () {
          this.credits = this.$store.getters.credits
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getCoins()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/style/sass/stylesheet/flix_color_style.scss'
  .router-link-exact-active
    border-bottom: 2px solid $brand-primary
</style>
